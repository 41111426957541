<template>
  <div>
    <siteHeader />

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <h3 class="mb-0">Member Group</h3>
            </div>
            <div class="card-body">
              <div class="row1">
                <card shadow type="secondary">
                  <form>
                    <div class="pl-lg-4">
                      <div class="row">
                        <div class="col-lg-6">
                          <base-input
                            alternative=""
                            label="Members Group Code"
                            :value="model.members_group_code"
                            @input="
                              (event) =>
                                (model.members_group_code = event.target.value)
                            "
                            input-classes="form-control-alternative"
                          />

                          <!-- <div
                            class="error mb-2"
                            v-if="v$.model.members_group_code.$error"
                          >
                            Members Group Code is required.
                          </div> -->
                        </div>
                        <div class="col-lg-6">
                          <base-input
                            alternative=""
                            label="Members Group Name"
                            input-classes="form-control-alternative"
                            :value="model.members_group_name"
                            @input="
                              (event) =>
                                (model.members_group_name = event.target.value)
                            "
                          />

                          <div
                            class="error mb-2"
                            v-if="v$.model.members_group_name.$error"
                          >
                            Members Group Name is required.
                          </div>
                        </div>
                      </div>
                      <div class="row1">
                        <div class="custom-control custom-checkbox mb-3">
                          <input
                            class="custom-control-input"
                            id="customCheck1"
                            type="checkbox"
                            v-model="model.is_active"
                            false-value="2"
                            true-value="1"
                          />
                          <label class="custom-control-label" for="customCheck1"
                            >Active</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="id.data == 'undefined'">
                      <div class="col-md-5"></div>
                      <div class="col-md-4">
                        <button
                          type="button"
                          class="btn btn-default"
                          v-on:click="create()"
                        >
                          Create
                        </button>
                      </div>
                    </div>
                    <div class="row" v-if="id.data != 'undefined'">
                      <div class="col-md-5"></div>
                      <div class="col-md-4">
                        <button
                          type="button"
                          class="btn btn-default"
                          v-on:click="Update()"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                  <br />
                  <div v-if="isLoading">
                    <base-alert type="success" dismissible>
                      <span class="alert-inner--text"
                        ><strong>Success!</strong> Record Created
                        Successfully</span
                      >
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </base-alert>
                  </div>
                  <div v-if="isUpdateLoading">
                    <base-alert type="success" dismissible>
                      <span class="alert-inner--text"
                        ><strong>Success!</strong> Record Updated
                        Successfully</span
                      >
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </base-alert>
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
const membergroupRepository = RepositoryFactory.get("membergroup");
export default {
  name: "Member Group",
  data() {
    return {
      v$: useVuelidate(),
      id: {},
      isLoading: false,
      isUpdateLoading: false,
      model: {
        members_group_code: "",
        members_group_name: "",
        is_active: "",
      },
    };
  },
  validations() {
    return {
      model: {
        // members_group_code: { required },
        members_group_name: { required },
      },
    };
  },

  methods: {
    // Pushes posts to the server when called.
    async create() {
      this.v$.$validate(); // checks all inputs
      if (this.v$.$error) {
        return;
      }

      if (this.model.is_active != 1) {
        this.model.is_active = "2";
      }

      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await membergroupRepository.create(data);
      if (response.data.status == 100) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          this.$router.push("/memberGroup/view_member_group");
        }, 1000);
      }
    },
    async Update() {
      this.v$.$validate(); // checks all inputs
      if (this.v$.$error) {
        return;
      }
      delete this.model.edit;
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await membergroupRepository.create(data);
      if (response.data.status == 101) {
        this.isUpdateLoading = true;
        setTimeout(() => {
          this.isUpdateLoading = false;
          this.$router.push("/memberGroup/view_member_group");
        }, 1000);
      }
      this.$router.push("/memberGroup/view_member_group");
    },
  },
  mounted() {
    this.id = this.$route.params;
    this.model = {};
    membergroupRepository.getMemberGroupById(this.id.data).then((data) => {
      if (data.data.length != 0) {
        this.model = data.data[0];
      }
    });
  },
};
</script>

<style></style>
